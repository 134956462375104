import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueQr from 'vue-qr'
import ElementUI from 'element-ui'
import i18n from './utils/i18n'
import Grid from 'vue-js-grid'
import Clipboard from 'v-clipboard'
import JsonExcel from 'vue-json-excel'
import tinymce from 'tinymce/tinymce'

import 'element-ui/lib/theme-chalk/index.css'

import 'tinymce/themes/silver'
import 'tinymce/icons/default'
import 'tinymce/plugins/lists'
import 'tinymce/plugins/image'
import 'tinymce/plugins/link'
import 'tinymce/plugins/autoresize'
import 'tinymce/plugins/quickbars'
import 'tinymce/plugins/advlist'
import 'tinymce/plugins/autolink'
import 'tinymce/plugins/charmap'
import 'tinymce/plugins/searchreplace'
import 'tinymce/plugins/visualblocks'
import 'tinymce/plugins/code'
import 'tinymce/plugins/fullscreen'
import 'tinymce/plugins/print'
import 'tinymce/plugins/preview'
import 'tinymce/plugins/anchor'
import 'tinymce/plugins/wordcount'
import 'tinymce/plugins/table'
import 'tinymce/plugins/insertdatetime'
import 'tinymce/plugins/help'
import 'tinymce/plugins/media'
import 'tinymce/plugins/paste'
import 'tinymce/skins/ui/oxide/skin.css'
import 'tinymce/skins/ui/oxide/skin.min.css'
import 'tinymce/skins/ui/oxide/content.css'
import 'tinymce/skins/ui/oxide/content.min.css'

// Initialize the app
tinymce.init({})

import locale from 'element-ui/lib/locale/lang/vi'
Vue.use(ElementUI, { locale })

import VueSplide from '@splidejs/vue-splide'
Vue.use(VueSplide)
import '@splidejs/splide/dist/css/themes/splide-default.min.css'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
// optional style for arrows & dots
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'

import VueFullscreen from 'vue-fullscreen'
Vue.use(VueFullscreen)

Vue.use(VueQr)
Vue.use(Grid)
Vue.use(Clipboard)
Vue.component('downloadExcel', JsonExcel)

import '@/styles/index.scss' // global css

import '@fortawesome/fontawesome-free/css/all.css'
import '@fortawesome/fontawesome-free/js/all.js'

import './middleware'

import * as filters from './utils/filters'
Object.keys(filters).forEach((key) => {
  Vue.filter(key, filters[key])
})

Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App)
}).$mount('#app')
