<template>
  <div class="navbar">
    <hamburger
      id="hamburger-container"
      class="hamburger-container"
      :is-active="opened"
      @toggleClick="toggleSideBar"
    />
    <button v-if="$route.params.id" class="btn-back" title="Quay lại" @click="handleBack">
      <i class="fas fa-arrow-left"></i>
    </button>
    <el-row type="flex" align="middle" class="right-menu">
      <el-dropdown class="language" trigger="click" @command="handleChangeLanguage">
        <el-row type="flex" align="middle">
          <img v-if="activeLanuage" :src="require(`@/assets/images/${activeLanuage.code}.png`)" alt />
        </el-row>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item
            v-for="item in languages"
            :key="item.code"
            :command="item.code"
            class="language-item"
          >
            <img :src="require(`@/assets/images/${item.code}.png`)" alt />
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
      <span class="user-name" @click="$router.push({name: 'InformationAccount'})">{{ loginInfo().auth_user.name }}</span>
      <span @click="handleLogout">
        <i class="fas fa-sign-out-alt btn-logout" />
      </span>
    </el-row>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import { loginInfo, logout } from '@/services/auth'
import Hamburger from './Hamburger/index'

export default {
  components: {
    Hamburger
  },
  data() {
    return {}
  },
  computed: {
    ...mapGetters(['constant', 'language']),
    ...mapState({
      opened: (state) => state.sidebar.opened,
      withoutAnimation: (state) => state.sidebar.withoutAnimation
    }),
    languages() {
      return this.constant && this.constant.constants ? this.constant.constants.locales : []
    },
    activeLanuage() {
      return this.languages.find((l) => l.code === this.language)
    }
  },
  methods: {
    loginInfo,
    ...mapActions('public', ['updateLanguage']),
    handleLogout() {
      this.$confirm('Bạn có chắc chắn muốn đăng xuất không?', 'Xác nhận', {
        confirmButtonText: 'Đăng xuất',
        cancelButtonText: 'Đóng',
        type: 'warning'
      }).then(() => {
        logout()
      }).catch(() => {})
    },

    handleBack() {
      const routerName = this.$route.name
      if (routerName.includes('Info')) {
        this.$router.push({ name: routerName.split('Info')[0] })
      }
      if (routerName.includes('Detail')) {
        this.$router.push({ name: routerName.split('Detail')[0] })
      }
      if (routerName.includes('Related')) {
        this.$router.push({ name: routerName.split('Related')[0] })
      }
    },

    toggleSideBar() {
      this.$store.dispatch('sidebar/toggleSideBar')
    },

    handleChangeLanguage(lang) {
      this.updateLanguage(lang)
    }
  }
}
</script>

<style lang="scss" scoped>
$bg:#630104;
.navbar {
  height: 50px;
  overflow: hidden;
  position: relative;
  background: $bg;
  box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
  color: #ffffff;
  .btn-logout {
    font-size: 24px;
    &:hover {
      cursor: pointer;
    }
    margin: 0 15px;
  }

  .hamburger-container {
    line-height: 46px;
    height: 100%;
    float: left;
    cursor: pointer;
    transition: background 0.3s;
    -webkit-tap-highlight-color: transparent;

    &:hover {
      background: rgba(0, 0, 0, 0.025);
    }
  }

  .btn-back {
    height: 100%;
    width: 50px;
    border-radius: 50%;
    background-color: transparent;
    border: none;
    color: #fff;
    font-size: 22px;
    &:hover {
      cursor: pointer;
      background-color: rgba(255, 255, 255, 0.123);
    }
  }

  .breadcrumb-container {
    float: left;
  }

  .errLog-container {
    display: inline-block;
    vertical-align: top;
  }

  .right-menu {
    float: right;
    height: 100%;
    line-height: 50px;
    padding-left: 20px;
    .language {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 15px;
      img {
        width: 32px;
        height: 32px;
        &:hover {
          cursor: pointer;
        }
      }
    }
    .user-name {
      padding: 0 15px;
      border-left: 0.1px solid #fff;
      border-right: 0.1px solid #fff;
      cursor: pointer;
    }
    span {
      display: flex;
      align-items: center;
    }
    &:focus {
      outline: none;
    }
    .right-menu-item {
      display: inline-block;
      padding: 0 8px;
      height: 100%;
      font-size: 18px;
      color: #5a5e66;
      vertical-align: text-bottom;

      &.hover-effect {
        cursor: pointer;
        transition: background 0.3s;

        &:hover {
          background: rgba(0, 0, 0, 0.025);
        }
      }
    }

    .avatar-container {
      margin-right: 30px;

      .avatar-wrapper {
        margin-top: 5px;
        position: relative;

        .user-avatar {
          cursor: pointer;
          width: 40px;
          height: 40px;
          border-radius: 10px;
        }

        .el-icon-caret-bottom {
          cursor: pointer;
          position: absolute;
          right: -20px;
          top: 25px;
          font-size: 12px;
        }
      }
    }
  }
}
</style>
<style lang="scss">
.language-item {
  display: flex;
  align-items: center;
  padding: 4px 8px !important;
  img {
    width: 32px;
    height: 32px;
  }
}
</style>
