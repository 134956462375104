export default {
  PLACES: [
    {
      name: 'Đất nước',
      value: 'country',
      type: 'place',
      hide: true
    },
    {
      name: 'Khu vực',
      value: 'area',
      type: 'place',
      hide: true
    },
    {
      name: 'Trang chủ',
      value: 'dashboard',
      type: 'place'
    },
    {
      name: 'Không gian văn hoá',
      value: 'museum',
      type: 'place',
      children: true
    },
    {
      name: 'Nhân vật',
      value: 'people',
      type: 'place',
      children: true
    },
    {
      name: 'Kiosk',
      value: 'kiosk',
      type: 'place'
    },
    {
      name: 'Tra cứu',
      value: 'lookup',
      type: 'place',
      children: true
    },
    {
      name: 'Danh thắng',
      value: 'attraction',
      type: 'place',
      children: true
    },
    {
      name: 'Giao thông',
      value: 'traffic',
      type: 'place',
      children: true
    },
    {
      name: 'Sự kiện và triển lãm',
      value: 'event',
      type: 'place'
    },
    {
      name: 'Lễ hội',
      value: 'festival',
      type: 'place'
    },
    {
      name: 'Gia đình',
      value: 'family',
      type: 'place'
    },
    {
      name: 'Địa điểm lưu trú',
      value: 'stay',
      type: 'place'
    },
    {
      name: 'Ăn uống',
      value: 'cuisine',
      type: 'place'
    },
    {
      name: 'Giải trí',
      value: 'entertainment',
      type: 'place'
    },
    {
      name: 'Doanh nghiệp',
      value: 'company',
      type: 'place'
    },
    {
      name: 'Chăm sóc sức khỏe',
      value: 'health',
      type: 'place'
    },
    {
      name: 'Bất động sản',
      value: 'real-estate',
      type: 'place'
    },
    {
      name: 'Cơ quan nhà nước',
      value: 'government-agency',
      type: 'place'
    },
    {
      name: 'Tài chính',
      value: 'financial',
      type: 'place'
    },
    {
      name: 'Golf',
      value: 'golf',
      type: 'place'
    },
    {
      name: 'Nhà hàng',
      value: 'restaurant',
      type: 'place'
    },
    {
      name: 'Làng nghề',
      value: 'craft-village',
      type: 'place'
    },
    {
      name: 'Kinh nghiệm du lịch',
      value: 'travel-experience',
      type: 'place'
    },
    {
      name: 'Quy Y',
      value: 'refuge',
      type: 'place'
    },
    {
      name: 'Hoạt động',
      value: 'activity',
      type: 'place'
    },
    {
      name: 'Phật học',
      value: 'buddhist-learning',
      type: 'place'
    },
    {
      name: 'Kinh phật',
      value: 'buddhist-scripture',
      type: 'place'
    },
    {
      name: 'Giáo hội',
      value: 'church',
      type: 'place'
    },
    {
      name: 'Thiện nguyện',
      value: 'volunteer',
      type: 'place'
    },
    {
      name: 'Nghĩa trang',
      value: 'cemetery',
      type: 'place'
    },
    {
      name: 'Hỏi đáp',
      value: 'qna',
      type: 'place'
    },
    {
      name: 'Bảo tàng video',
      value: 'video-library',
      type: 'place'
    },
    {
      name: 'Bảo tàng âm thanh',
      value: 'sound-library',
      type: 'place'
    },
    {
      name: 'Bảo tàng tài liệu',
      value: 'document-library',
      type: 'place'
    },
    {
      name: 'Bảo tàng hình ảnh',
      value: 'image-library',
      type: 'place'
    },
    {
      name: 'Campaign',
      value: 'campaign',
      type: 'place'
    },
    {
      name: 'Dịch vụ khác',
      value: 'other',
      type: 'place'
    },
    {
      name: 'Tour du lịch mẫu',
      value: 'tour',
      type: 'tour'
    },
    {
      name: 'Tour du lịch thương mại',
      value: 'commerce',
      type: 'tour'
    },
    {
      name: 'Bài viết Blog - chia sẻ',
      value: 'post',
      type: 'post'
    },
    {
      name: 'Phân hệ',
      value: 'hierarchy',
      type: 'hierarchy',
      hide: true
    }
  ],
  COLUMNS: [
    {
      value: 'stt',
      label: 'STT',
      key: 'stt',
      width: '80',
      align: 'left'
    },
    {
      value: 'avatar',
      label: '',
      key: 'avatar',
      width: '100',
      align: 'left'
    },
    {
      value: 'name',
      label: 'Tên',
      key: 'name',
      width: '',
      align: 'left',
      sortable: 'custom'
    },
    {
      value: 'parent',
      label: 'Cha',
      key: 'parent',
      width: '200',
      align: 'left',
      sortable: 'custom'
    },
    {
      value: 'created_at',
      label: 'Ngày tạo',
      key: 'created_at',
      width: '120',
      align: 'center',
      sortable: 'custom'
    },
    {
      value: 'actions',
      label: 'Thao tác',
      key: 'actions',
      width: '250',
      align: 'center'
    },
    {
      value: 'organization',
      label: 'Tổ chức',
      key: 'organization',
      width: '250',
      align: 'left'
    },
    {
      value: 'language',
      label: 'Ngôn ngữ',
      key: 'language',
      width: '200',
      align: 'center'
    },

    // Comment table
    {
      value: 'c_object',
      label: 'Bài đăng',
      key: 'object',
      width: '200',
      align: 'left'
    },
    {
      value: 'c_creator',
      label: 'Tài khoản đăng',
      key: 'creator',
      width: '200',
      align: 'left'
    },
    {
      value: 'content',
      label: 'Nội dung',
      key: 'content',
      width: '',
      align: 'left',
      sortable: 'custom'
    },
    {
      value: 'c_approved',
      label: 'Trạng thái',
      key: 'approved',
      width: '100',
      align: 'left'
    },
    {
      value: 'c_created_at',
      label: 'Ngày đăng',
      key: 'created_at',
      width: '120',
      align: 'center',
      sortable: 'custom'
    },

    // Campaign table
    {
      value: 'cam_avatar',
      label: 'Banner',
      key: 'avatar',
      width: '100',
      align: 'left'
    },
    {
      value: 'cam_name',
      label: 'Tên campaign',
      key: 'name',
      width: '',
      align: 'left',
      sortable: 'custom'
    },
    {
      value: 'cam_times',
      label: 'Ngày áp dụng',
      key: 'times',
      width: '250',
      align: 'center'
    },

    // Area table
    {
      value: 'country',
      label: 'Đất nước',
      key: 'country',
      width: '250',
      align: 'left'
    }
  ],
  ROLES: [
    {
      value: 'administrator',
      label: 'Quản lý',
      rules: ['administrator']
    },
    {
      value: 'editor',
      label: 'Nhân viên',
      rules: ['administrator']
    },
    {
      value: 'moderator',
      label: 'Nhân viên tổ chức',
      rules: ['administrator', 'editor']
    },
    {
      value: 'cook',
      label: 'Đầu bếp',
      rules: ['administrator', 'editor', 'moderator']
    },
    {
      value: 'cashier',
      label: 'Thu ngân',
      rules: ['administrator', 'editor', 'moderator']
    },
    {
      value: 'user',
      label: 'Người dùng',
      rules: ['administrator', 'editor', 'moderator']
    }
  ],
  HEADER_ACTIONS: [
    {
      label: 'Thêm',
      key: 'add',
      icon: 'el-icon-circle-plus-outline',
      type: 'primary'
    },
    {
      label: 'Bộ lọc',
      key: 'filter',
      icon: 'el-icon-s-order',
      type: 'primary'
    },
    {
      label: 'Làm mới',
      key: 'reset',
      icon: 'el-icon-refresh-right',
      type: 'primary'
    },
    {
      label: 'Đồng bộ',
      key: 'synchronize',
      icon: 'el-icon-s-promotion',
      type: 'primary'
    },
    {
      label: 'Export',
      key: 'export',
      icon: 'el-icon-download',
      type: 'primary'
    },
    {
      label: 'Đơn hàng',
      key: 'cashier',
      icon: 'el-icon-shopping-cart-1',
      type: 'primary'
    },
    {
      label: 'Đầu bếp',
      key: 'cook',
      icon: 'el-icon-knife-fork',
      type: 'primary'
    },
    {
      label: 'Thống kê',
      key: 'statistic',
      icon: 'el-icon-s-data',
      type: 'primary'
    },
    {
      label: 'Nhãn',
      key: 'tag',
      icon: 'el-icon-collection-tag',
      type: 'primary'
    }
  ],
  ALL_ACTIONS: [
    {
      label: 'Xem mã QRCode',
      key: 'showQR',
      icon: 'fas fa-qrcode',
      type: 'info'
    },
    {
      label: 'Chi tiết',
      key: 'detail',
      icon: 'el-icon-document',
      type: 'primary'
    },
    {
      label: 'Chỉnh sửa',
      key: 'edit',
      icon: 'el-icon-edit',
      type: 'primary'
    },
    {
      label: 'Xóa',
      key: 'delete',
      icon: 'el-icon-delete',
      type: 'danger'
    },
    {
      label: 'Duyệt',
      key: 'browser',
      icon: 'el-icon-check',
      type: 'success'
    },
    {
      label: 'Bỏ duyệt',
      key: 'unbrowser',
      icon: 'el-icon-minus',
      type: 'info'
    },
    {
      label: 'Copy public link',
      key: 'copy',
      icon: 'el-icon-copy-document',
      type: 'info'
    },
    {
      label: 'Danh sách liên kết',
      key: 'related',
      icon: 'el-icon-connection',
      type: 'primary'
    }
  ],
  INPUTS: [
    {
      value: 'name',
      label: 'Tên',
      placeholder: 'Nhập tên, định danh',
      key: 'name',
      ref: 'form-name',
      type: 'input',
      required: true
    },
    {
      value: 'ordered',
      label: 'STT hiển thị',
      placeholder: 'Nhập số thứ tự hiển thị mong muốn',
      key: 'ordered',
      ref: 'form-ordered',
      type: 'number'
    },
    {
      value: 'qr_code',
      label: 'Qr code',
      placeholder: 'Nhập vào giá trị qr code',
      key: 'qr_code',
      ref: 'form-qr-code',
      type: 'input',
      required: true
    },
    {
      value: 'vrtour',
      label: 'Link Vrtour',
      placeholder: 'Nhập vào link vrtour',
      key: 'vrtour',
      type: 'input'
    },
    {
      value: 'vrtour_trailer',
      label: 'Link Trailer',
      placeholder: 'Nhập vào link trailer',
      key: 'vrtour_trailer',
      type: 'input'
    },
    {
      value: 'description',
      label: 'Mô tả',
      placeholder: 'Nhập vào mô tả ngắn gọn',
      key: 'description',
      type: 'textarea'
    },
    {
      value: 'content',
      label: 'Nội dung',
      placeholder: 'Nhập vào nội dung chi tiết',
      key: 'content',
      type: 'editor'
    },
    {
      value: 'area_content',
      label: 'Mô tả',
      placeholder: 'Nhập vào mô tả chi tiết',
      key: 'content',
      type: 'editor'
    },
    {
      value: 'country_content',
      label: 'Giới thiệu',
      placeholder: 'Nhập vào nội dung chi tiết',
      key: 'content',
      type: 'editor'
    },
    {
      value: 'local_cuisine',
      label: 'Ẩm thực địa phương',
      placeholder: 'Nhập vào nội dung chi tiết',
      key: 'local_cuisine',
      type: 'editor'
    },
    {
      value: 'travel_experience',
      label: 'Kinh nghiệm du lịch',
      placeholder: 'Nhập vào nội dung chi tiết',
      key: 'travel_experience',
      type: 'editor'
    },
    {
      value: 'vehicle_info',
      label: 'Phương tiện di chuyển',
      placeholder: 'Nhập vào nội dung chi tiết',
      key: 'vehicle_info',
      type: 'editor'
    },
    {
      value: 'festival_info',
      label: 'Thông tin lễ hội',
      placeholder: 'Nhập vào nội dung chi tiết',
      key: 'festival_info',
      type: 'editor'
    },
    {
      value: 'fun_facts',
      label: 'Những điều lý thú',
      placeholder: 'Nhập vào nội dung chi tiết',
      key: 'fun_facts',
      type: 'editor'
    },
    {
      value: 'navigation',
      label: 'Di chuyển',
      placeholder: 'Nhập vào thông tin',
      key: 'navigation',
      type: 'editor'
    },
    {
      value: 'route_map',
      label: 'Route Map - Kinh nghiệm du lịch',
      placeholder: 'Nhập vào thông tin',
      key: 'route_map',
      type: 'editor'
    },
    {
      value: 't_name',
      label: 'Tên Tour',
      placeholder: 'Nhập vào tên tour',
      key: 'name',
      type: 'input',
      ref: 'form-name',
      required: true
    },
    {
      value: 'price',
      label: 'Giá (VNĐ)',
      placeholder: 'Nhập vào giá tour',
      key: 'price',
      type: 'number',
      min: 0
    },
    {
      value: 'distance',
      label: 'Khoảng cách (km)',
      placeholder: 'Nhập vào khoảng cách',
      key: 'distance',
      type: 'number',
      min: 0
    },
    {
      value: 'agent_info',
      label: 'Thông tin đơn vị lữ hành',
      placeholder: 'Nhập thông tin',
      key: 'agent_info',
      type: 'editor'
    },
    {
      value: 'policy',
      label: 'Chính sách Tour',
      placeholder: 'Nhập thông tin',
      key: 'policy',
      type: 'editor'
    },
    {
      value: 'tip_hour',
      label: 'Tip & Hours',
      placeholder: 'Nhập thông tin',
      key: 'tip_hour',
      type: 'editor'
    },
    {
      value: 'cam_name',
      label: 'Tên campaign',
      placeholder: 'Nhập vào tên campaign',
      key: 'name',
      type: 'input',
      ref: 'form-name',
      required: true
    }
  ]
}

